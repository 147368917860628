<template>
  <div class="perspective-container">
    <div class="perspective-name">
      <div class="name-line"></div>
      <div id="first" class="name-line">
        <p id="kel">Kel</p>
      </div>
      <div id="last" class="name-line">
        <p id="strother2" class="text-line">Strother</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Name",
};
</script>

<style>
.perspective-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.perspective-name {
  perspective: 500px;
  font-size: 65px;
  position: absolute;
  top: -2em;
  left: -1.75em;
}
.name-line {
  perspective: 500px;
  transform-style: preserve-3d;
  height: 57px;
  position: relative;
}
#kel,
#strother2 {
  font-family: "lexthin";
  margin: 0;
  height: 57px;
  line-height: 57px;
  letter-spacing: 3.5px;
}
.name-line:nth-child(2) {
  animation: face-off 0.5s ease-in 3.2s both;
  transform-style: preserve-3d;
  transform: skew(0deg, -20deg) scale(1.1, 1.5);
  left: 2rem;
  font-weight: 100;
  color: var(--deepblue);
}
.name-line:nth-child(3) {
  transform-style: preserve-3d;
  transform: skew(70deg, -20deg) scaley(0.6);
  left: 3rem;
  letter-spacing: 4px;
  font-weight: 100;
  -webkit-text-stroke: 0 rgba(255, 255, 255, 0.253);
}
#strother2 {
  color: transparent;
  background: linear-gradient(80deg, var(--deepwhite) 63%, var(--deepblue) 65%);
  background-clip: text;
  -webkit-background-clip: text;
}
#last {
  animation: face-off 0.5s linear 3.5s both;
}
#last::before {
  content: "Strother";
  font-family: "lexlight";
  position: absolute;
  top: 60%;
  right: 0;
  transform-style: preserve-3d;
  transform: translate(3px, 0) rotateY(-3deg) skew(15deg, -1.5deg)
    scale(0.6, 0.65);
  letter-spacing: 16px;
  filter: blur(3px);
  color: rgba(0, 136, 170, 0.2);
  animation: blurring 5s infinite ease-in-out;
}

@media screen and (min-width: 700px) {
  .perspective-name {
    font-size: 6rem;
    top: 12rem;
    left: -10rem;
  }
  .name-line {
    height: 80px;
  }
  #kel,
  #strother2 {
    height: 80px;
    line-height: 80px;
  }
}

@media screen and (min-width: 1024px) {
  .perspective-name {
    font-size: 6rem;
    top: -2rem;
    left: -3.8em;
  }
  #kel,
  #strother2 {
    height: 90px;
  }
  .text-line {
    height: 90px;
    line-height: 90px;
  }
}

@media screen and (min-width: 1280px) {
  .perspective-name {
    font-size: 7rem;
    top: 0.5rem;
    left: -4.5em;
  }
  .name-line {
    height: 105px;
  }
  #kel,
  #strother2 {
    height: 105px;
    line-height: 105px;
  }
  #first::before {
    top: -13%;
  }
  .perspective-name:hover p {
    transform: translate(0, -105px);
  }
}
@media screen and (min-width: 1440px) {
  .perspective-name {
    font-size: 9rem;
    top: 0.5rem;
    left: -3.5em;
  }
  .name-line {
    height: 115px;
  }
  #kel,
  #strother2 {
    height: 115px;
    line-height: 115px;
  }
  #first::before {
    top: -27%;
  }
  .perspective-name:hover p {
    transform: translate(0, -115px);
  }
}
@media screen and (min-width: 2550px) {
  .perspective-name {
    font-size: 10rem;
  }
  .name-line {
    height: 155px;
  }
  #kel,
  #strother2 {
    height: 155px;
    line-height: 155px;
  }
  .name-line:nth-child(2) {
    transform: skew(0deg, -20deg) scale(1.2, 1.7);
  }
  .name-line:nth-child(3) {
    transform: skew(70deg, -20deg) scaley(0.7) scaleX(1.2);
  }
}
</style>
