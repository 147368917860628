<template>
  <div class="container">
    <div class="icon-container">
      <a
        class="svg-link"
        target="_blank"
        href="https://www.linkedin.com/in/kelstrother/"
      >
        <img
          id="linked-in"
          class="linked"
          src="../assets/socials/iconfinder-icon.svg"
          alt="linkedin Profile"
        />
        <span class="linked-shadow"></span>
      </a>
      <a
        class="svg-link"
        target="_blank"
        href="https://codepen.io/collection/vBZRzL"
      >
        <img
          id="codepen"
          class="linked"
          src="../assets/socials/codepen.svg"
          alt="Codepen Projects"
        />
        <span class="codepen-shadow"></span>
      </a>
      <a class="svg-link" target="_blank" href="https://github.com/kelstrother">
        <img
          id="github"
          class="linked"
          src="../assets/socials/github.svg"
          alt="Github Profile"
        />
        <span class="github-shadow"></span>
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  display: none;
}

@media screen and (min-width: 700px) {
  .container {
    display: flex;
    z-index: 25;
  }
  .icon-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 5vw;
    height: 20vh;
    position: absolute;
    bottom: 5vh;
    right: 1%;
    z-index: 45;
    padding-bottom: 1rem;
    animation: splat-3 1s ease-out 5s both;
  }
  img {
    height: 2rem;
    width: 2rem;
    opacity: 0.7;
  }
  .linked:hover {
    filter: invert(0.4) sepia(1) hue-rotate(10deg) saturate(1.5)
      brightness(110%);
    animation: twitch 0.3s both;
  }
}

@media screen and (min-width: 2550px) {
  .icon-container {
    width: 3vw;
    right: 0;
  }
  img {
    height: 2.3rem;
    width: 2.3rem;
  }
}
</style>
