<template>
  <div class="container">
    <h1 class="title" id="about.me">The name's Kel</h1>
    <img
      class="blue-ink2"
      src="../assets/img/blue-ink.png"
      alt="subtle blue paint splatter"
    />
    <img
      class="yellow-ink2"
      src="../assets/img/yellow-ink.png"
      alt="subtle yellow paint splatter"
    />
    <div class="auto-bio">
      <p class="biography" id="part1">
        I live in the glorious state of Colorado. When I'm not creating things
        with code, I'm likely out seeing live music or writing and performing
        music of my own.
      </p>
      <p class="biography" id="part2">
        I am a trained Full Stack developer, but my passion for creative
        expression has led me toward a focus on front-end development. My
        background as a traveling musician has taught me to embrace being
        outside my comfort zone while still exceeding expectations under
        pressure. This provides me with a unique method for solving problems
        that result in products that are sure to impress.
      </p>
    </div>
    <div class="scroll-container">
      <img src="../assets/img/scroll.png" alt="scroll down" class="scroll">
      <!-- <p class="scroll">\/</p> -->
      <!-- <span class="scroll">&#10151;</span> -->
    </div>
    <div class="download">
      <a
        class="dl-res"
        href="Resume/Kel_Strother-Resume.pdf"
        target="_blank"
        download="Kel Strother Resume"
      >
        <p class="dl-my-res">Download My Resume</p>
      </a>
    </div>
    <img
      class="guitars"
      src="../assets/img/2_guitars.png"
      alt="kel equipped with two dueling guitars, ready for whatever may cross his path!"
    />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  place-items: center;
}
.title {
  color: rgba(0, 0, 0, 1);
  position: absolute;
  top: 10vh;
  left: 11vw;
  -webkit-text-stroke: 1px rgba(242, 242, 255, 0.7);
  font-size: 2rem;
  font-family: "lextrabold";
  font-weight: 700;
  letter-spacing: 0.1rem;
  text-align: center;
  transform: rotate(-5deg);
  z-index: 10;
}
.title::before {
  z-index: 2;
  content: "The name's Kel";
  position: absolute;
  top: 0;
  left: 0;
  -webkit-text-stroke: 0.5px rgba(4, 182, 226, 1);
  transform: rotate(1.1deg) translate(3.5px, -2px);
}
.yellow-ink2,
.blue-ink2 {
  z-index: 5;
  position: absolute;
  opacity: 0.7;
  width: 60vw;
}
.yellow-ink2 {
  left: 40%;
  bottom: 0;
}
.blue-ink2 {
  bottom: 5%;
  left: -4%;
  transform: rotate(-90deg);
}
.guitars {
  width: 90vw;
  max-width: 500px;
  height: auto;
  z-index: 5;
  position: absolute;
  bottom: 0;
  right: 0%;
}
.auto-bio {
  overflow-y: auto;
  font-family: "lexlight";
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  position: absolute;
  top: 20%;
  left: 14%;
  align-items: center;
  color: rgba(4, 182, 226, 1);
  width: 76vw;
  height: 35vh;
  font-size: 0.9rem;
  max-width: 650px;
  z-index: 10;
  transform: rotate(-5deg);
  border-right: 1px inset var(--deepgold);
}
#part1,
#part2 {
  text-indent: 0.75em;
  display: inline-block;
}
#part2 {
  margin-top: 1em;
}
a {
  text-decoration: none;
}
.scroll {
  animation: bounce 1s infinite ease;
  transform: rotate(-5deg);
  left: 45%;
  opacity: 0.7;
  position: absolute;
  top: 58%;
}
.download {
  font-family: "lexlight";
  display: flex;
  overflow: hidden;
  position: absolute;
  z-index: 10;
  transform: rotate(-5deg);
  top: 64%;
  left: 26%;
  flex-direction: column;
  width: 60vw;
  background: linear-gradient(
    175deg,
    rgb(1, 97, 138) -10%,
    rgba(0, 0, 0, 0) 45%,
    rgba(0, 0, 0, 0) 70%,
    rgb(1, 97, 138) 150%
  );
  height: 5vh;
  max-width: 400px;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  /* box-shadow: 0 0 2px 0.5px var(--deepblue); */
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  outline: 2px solid transparent;
}
.download::before {
  content: "";
  position: absolute;
  top: 0;
  width: 110%;
  height: 0.75px;
  background-color: var(--deepblue);
  opacity: 0;
}
.download::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: -100%;
  width: 110%;
  height: 0.75px;
  background-color: var(--deepblue);
  opacity: 0;
}
.download:hover {
  background: linear-gradient(
    175deg,
    rgba(253, 209, 11, 0.3) -10%,
    rgba(0, 0, 0, 0) 45%,
    rgba(0, 0, 0, 0) 70%,
    rgba(253, 209, 11, 0.6) 150%
  );
}
.download:hover .dl-my-res {
  color: rgba(4, 182, 226, 0.8);
}
.dl-my-res {
  display: inline-block;
  font-size: 0.9rem;
  letter-spacing: 1.2px;
  color: rgba(253, 209, 11, 0.6);
  transition: 0.3s ease-in-out;
}
@media screen and (min-width: 700px) {
  .title {
    top: 23%;
    left: 14%;
    font-size: 3.8rem;
  }
  .guitars {
    right: 20%;
  }
  .auto-bio {
    top: 35%;
    left: 16%;
    width: 65vw;
    font-size: 1rem;
    letter-spacing: 1px;
    border: none;
  }
  .scroll {
    display: none;
  }
  .download {
    top: 58%;
  }
  .dl-my-res {
    letter-spacing: 1.5px;
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 1023px) {
  .title {
    top: 17%;
    left: 23%;
  }
  .yellow-ink2 {
    width: 30vw;
    transform: scaleX(1.5) scaleY(1.5) rotate(0deg);
    opacity: 0.1;
    top: 20%;
    left: 60%;
  }
  .blue-ink2 {
    width: 40vw;
    bottom: 8%;
    left: 5%;
    background: none;
    transform: scaleY(1.3) scaleX(1.5) rotateX(180deg);
    opacity: 0.2;
    z-index: 0;
  }
  .auto-bio {
    top: 29%;
    left: 20%;
  }
  .download {
    top: 58%;
    left: 40%;
    width: 20rem;
    transition: all 0.3s;
  }
  .download::before,
  .download::after {
    transition: all 0.3s;
  }
  .download:hover {
    background: linear-gradient(
      175deg,
      rgba(253, 209, 11, 0.3) -10%,
      rgba(0, 0, 0, 0) 45%,
      rgba(0, 0, 0, 0) 70%,
      rgba(253, 209, 11, 0.3) 150%
    );
    overflow: visible;
  }
  .download:hover:before {
    left: 0;
    opacity: 1;
  }
  .download:hover:after {
    right: 0;
    opacity: 1;
  }
}

@media screen and (min-width: 1280px) {
  .title {
    left: 14%;
    font-size: 4.5rem;
  }
  .auto-bio {
    left: 14%;
    top: 35%;
  }
  .download {
    top: 65%;
    left: 25%;
  }
  .guitars {
    right: 3%;
  }
}
@media screen and (min-width: 1440px) {
  .title {
    font-size: 5rem;
    top: 22%;
    left: 18%;
  }
  .yellow-ink2 {
    width: 40vw;
    top: 10%;
    left: 50%;
    transform: scaleX(1.5) rotate(0deg);
    opacity: 0.1;
  }
  .blue-ink2 {
    display: flex;
    width: 40vw;
    bottom: 12%;
    background: none;
    transform: scaleY(1.4) scaleX(1.4) rotateX(180deg);
    opacity: 0.2;
  }
  .auto-bio {
    left: 20%;
    font-size: 1.15rem;
  }
  .download {
    width: 25rem;
    max-width: 500px;
    left: 30%;
  }
}

@media screen and (min-width: 2550px) {
  .title {
    font-size: 6.25rem;
    top: 25%;
    left: 24%;
    letter-spacing: 5px;
  }
  .auto-bio {
    top: 37%;
    left: 27%;
    max-width: 750px;
  }
  .download {
    top: 58%;
    left: 34%;
  }
  .dl-my-res {
    font-size: 1.4rem;
  }
  .guitars {
    max-width: 800px;
    width: 50rem;
  }
}
</style>
