<template>
  <div class="container">
    <div class="backdrop"></div>
    <footer class="statement">
      <p>
        <small class="copyright"
          >&copy;2021 all rights reserved etc... kelstrother.dev</small
        >
      </p>
    </footer>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  width: 100vw;
  height: 4vh;
}
.backdrop {
  height: 4vh;
  width: 100%;
  background: rgba(1, 162, 255, 1);
  filter: blur(1px);
  position: absolute;
  bottom: 0;
  z-index: 2;
}
.copyright {
  display: flex;
  font-family: "lexlight";
  justify-content: center;
  align-items: center;
  letter-spacing: 0.4px;
  width: 100vw;
  font-size: 0.7rem;
  position: absolute;
  color: rgba(255, 255, 255, 0.7);
  background: transparent;
  bottom: 8px;
  z-index: 10;
}

@media screen and (min-width: 700px) {
  .container {
    height: 5vh;
  }
  .backdrop {
    height: 5vh;
  }
  .copyright {
    text-align: center;
    font-size: 0.8rem;
    bottom: 15px;
  }
}

@media screen and (min-width: 1440px) {
  .copyright {
    font-size: 1rem;
  }
}
@media screen and (min-width: 2550px) {
  .copyright {
    bottom: 20px;
  }
}
</style>
