<template>
  <div class="mobile-container">
    <transition name="slide">
      <div v-if="showNav" class="sidenav-container">
        <div class="icon-container">
          <a
            class="svg-link"
            target="_blank"
            href="https://www.linkedin.com/in/kelstrother/"
          >
            <img
              id="linked-in"
              class="linked"
              src="../assets/socials/iconfinder-icon.svg"
              alt="linkedin Profile"
            />
            <span class="linked-shadow"></span>
          </a>
          <a
            class="svg-link"
            target="_blank"
            href="https://codepen.io/collection/vBZRzL"
          >
            <img
              id="codepen"
              class="linked"
              src="../assets/socials/codepen.svg"
              alt="Codepen Projects"
            />
            <span class="codepen-shadow"></span>
          </a>
          <a
            class="svg-link"
            target="_blank"
            href="https://github.com/kelstrother"
          >
            <img
              id="github"
              class="linked"
              src="../assets/socials/github.svg"
              alt="Github Profile"
            />
            <span class="github-shadow"></span>
          </a>
        </div>
        <ul class="sn-ul">
          <li @click="showNav = !showNav" class="sn-link">
            <router-link data-text="home" class="sidenav-link" to="/"
              >home
            </router-link>
          </li>
          <li @click="showNav = !showNav" class="sn-link">
            <router-link data-text="my.work" class="sidenav-link" to="/mywork"
              >my.work
            </router-link>
          </li>
          <li @click="showNav = !showNav" class="sn-link">
            <router-link data-text="about" class="sidenav-link" to="/about"
              >about
            </router-link>
          </li>
          <li @click="showNav = !showNav" class="sn-link">
            <router-link data-text="contact" class="sidenav-link" to="/contact"
              >contact</router-link
            >
          </li>
        </ul>
      </div>
    </transition>
    <div @click="showNav = !showNav" class="menu-button">
      <span class="line1"></span>
      <span class="line2"></span>
      <span class="line3"></span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showNav: false,
    };
  },
};
</script>

<style scoped>
.slide-enter-from {
  opacity: 0.5;
  transform: translate(130%, 100%);
}
.slide-enter-to {
  opacity: 1;
  transform: translate(0%, 0%);
}
.slide-enter-active {
  transition: all 0.5s ease;
}
.slide-leave-from {
  opacity: 1;
  transform: translate(0%, 0%);
}
.slide-leave-to {
  opacity: 0.5;
  transform: translate(130%, 100%);
}
.slide-leave-active {
  transition: all 0.5s ease;
}
.menu-button {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: transparent;
  filter: drop-shadow(2px 5px 2px rgba(0, 0, 0, 0.35));
  border-radius: 10px;
  position: fixed;
  height: 2.5em;
  width: 3.5em;
  bottom: 2%;
  right: 1.2em;
  z-index: 30;
  backdrop-filter: blur(2px);
}
span {
  height: 0.25em;
  width: 3em;
}
.line1 {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1.5px outset rgb(1, 162, 255);
  transform: perspective(200px) skew(15deg, 0deg) rotate(-2deg);
}
.line2 {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1.5px outset rgba(255, 195, 15, 1);
  transform: skew(-15deg, 0deg) rotate(0deg);
  transition: 0.1s linear;
}
.line3 {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1.5px outset rgb(1, 162, 255);
  transform: skew(15deg, 0deg) rotate(2deg);
}
.sidenav-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  bottom: 0px;
  right: 0px;
  height: 550px;
  width: 60vw;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 40px 40px 0 50px;
  border-top: 0.5px outset var(--deepblue);
  backdrop-filter: blur(6px);
  box-shadow: -5px -2px 15px rgba(0, 0, 0, 0.8);
  z-index: 15;
}
.icon-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1.5rem;
  width: 90%;
  height: 10%;
  transform: rotate(-10deg);
  z-index: 90;
}
.svg-link {
  z-index: 30;
}
img {
  height: 1.8rem;
  width: 1.8rem;
  opacity: 0.6;
}
.sn-ul {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 90%;
  height: 100%;
  border-radius: 40px 40px 0 50px;
  list-style: none;
  padding-bottom: 1.5rem;
  padding-top: 1rem;
  z-index: 25;
}
.sn-link {
  transform: rotate(-10deg);
  z-index: 35;
  font-family: "lextrabold";
}
.sidenav-link {
  font-size: 2.3rem;
  text-decoration: none;
  color: rgb(0, 0, 0);
  -webkit-text-stroke: 0.35px var(--brightblue);
  letter-spacing: 0.5px;
  filter: brightness(120%);
  z-index: 30;
}
.router-link-exact-active {
  font-size: 2.35rem;
  z-index: 30;
  color: rgba(0, 0, 0, 0);
  -webkit-text-stroke: 0.5px var(--brightgold);
  filter: brightness(150%);
  letter-spacing: 2px;
}
/* .router-link-exact-active::after {
  content: attr(data-text);
  position: absolute;
  top: -4px;
  left: -1.4px;
  letter-spacing: -1.25px;
  font-size: 2.3rem;
  color: transparent;
  -webkit-text-stroke: 0.75px var(--brightblue);
  z-index: -1;
} */
.router-link-exact-active::after {
  display: none;
  content: attr(data-text);
  position: absolute;
  top: 1px;
  left: 0px;
  letter-spacing: 4px;
  font-size: 1.95rem;
  font-weight: 200;
  -webkit-text-stroke: 0 var(--brightblue);
  color: rgba(4, 186, 226, 0.6);
  filter: brightness(80%);
  z-index: 1;
}

@media screen and (min-width: 700px) {
  .mobile-container {
    display: none;
  }
}
</style>
