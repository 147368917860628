<template>
  <div class="home">
    <header class="name-container">
      <Name />
    </header>
    <div class="skewed-job">
      <p id="title-web" class="web1">Web</p>
      <p class="developer">Developer</p>
    </div>
    <div class="image-container">
      <img
        class="splat"
        src="../assets/img/paint_splatter.png"
        alt="paint splatter"
      />
      <img
        class="blue-ink"
        src="../assets/img/blue-ink.png"
        alt="blue paint splatter"
      />
      <img
        class="yellow-ink"
        src="../assets/img/yellow-ink.png"
        alt="yellow paint splatter"
      />
      <img
        src="../assets/img/large-thumb.png"
        alt="a huge thumbs up!"
        class="big-thumb"
      />
      <img
        src="../assets/img/thumb-only.png"
        alt="a smaller thumbs up!"
        class="thumb"
      />
      <img
        class="missing-thumb"
        src="../assets/img/missing-thumb.png"
        alt="kel just pondering everything that is..."
      />
    </div>
  </div>
</template>

<script>
import Name from "../components/Name";
export default {
  components: { Name },
  name: "Home",
};
</script>

<style scoped>
/* * {
  border: 1px solid red;
} */
.home {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100vw;
  width: 100vw;
}
.image-container {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.blue-ink,
.yellow-ink {
  display: none;
  position: absolute;
  top: 0%;
  right: -35%;
  width: 30rem;
  opacity: 0.4;
  transform: rotate(35deg);
}
.thumb,
.splat,
.missing-thumb {
  position: absolute;
  bottom: 0;
  left: -5%;
  height: auto;
  width: 21rem;
}
.splat {
  z-index: 0;
}
.thumb {
  z-index: 5;
  animation: face-off 0.3s ease 2.3s both;
}
.missing-thumb {
  animation: grower-not-a-shower 2s ease-out 1.5s both;
  z-index: 5;
}
.big-thumb {
  display: flex;
  place-items: center;
  transform-origin: center;
  width: 100vw;
  height: auto;
  z-index: 15;
  transform: scale(1);
  margin: 80% 0 0 0;
  animation: thumbs-up 2.9s ease-in-out both;
}
.name-container {
  position: absolute;
  top: 20%;
  left: 32%;
}
.skewed-job {
  position: absolute;
  top: 37%;
  left: 20%;
  font-size: 3rem;
  width: auto;
  height: auto;
  font-family: "lexthin";
  font-weight: 100;
  margin-top: 1em;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  opacity: 0.8;
  letter-spacing: 3px;
}
#title-web {
  display: inline-block;
  margin-left: -0.9em;
  color: transparent;
  background: linear-gradient(
    90deg,
    var(--brightblue) 35%,
    var(--brightwhite) 36%
  );
  background-clip: text;
  -webkit-background-clip: text;
  z-index: 7;
  animation: face-off 1.4s ease-out 3.9s both;
}
.developer {
  z-index: 0;
  color: transparent;
  background: linear-gradient(
    90deg,
    var(--brightwhite) 36%,
    var(--brightblue) 38%
  );
  background-clip: text;
  -webkit-background-clip: text;
  animation: face-off 1.6s ease-out 4.2s both;
}

@media screen and (min-width: 700px) {
  .name-container {
    top: -6em;
    left: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .thumb,
  .splat,
  .missing-thumb {
    width: 35rem;
    bottom: 0;
    left: -5%;
  }
  .big-thumb {
    margin: 40% 0 0 0;
    animation: thumbs-up-responsive 2.9s ease-in-out both;
  }
  .skewed-job {
    top: 45%;
    left: 40%;
  }
  .web1 {
    font-size: 4rem;
  }
  .developer {
    font-size: 4rem;
  }
}

@media screen and (min-width: 1024px) {
  .name-container {
    top: 12%;
    left: 45%;
  }
  .thumb,
  .splat,
  .missing-thumb {
    width: 35rem;
    left: -3%;
  }
  .big-thumb {
    margin: 10% 0 0 0;
    animation: thumbs-up-desktop 2.9s ease-in-out both;
  }
  .skewed-job {
    top: 40%;
    left: 53%;
  }
}

@media screen and (min-width: 1280px) {
  .name-container {
    top: 4%;
    left: 57%;
  }
  .thumb,
  .splat,
  .missing-thumb {
    left: -1%;
    width: 32rem;
  }
  .thumb {
    animation: face-off 0.3s ease 2.2s both;
  }
  .missing-thumb {
    animation: grower-not-a-shower 2s ease-out 1.8s both;
  }
  .big-thumb {
    margin: 0;
    animation: thumbs-up-large 2.7s ease-in-out both;
  }
  .skewed-job {
    top: 50%;
    left: 50%;
  }
}

@media screen and (min-width: 1440px) {
  .home {
    overflow: hidden;
    height: 100vh;
  }
  .name-container {
    top: 4%;
    left: 50%;
  }
  .thumb,
  .splat,
  .missing-thumb {
    left: -1%;
    width: 40rem;
  }
  .skewed-job {
    top: 43%;
    left: 50%;
  }
  .web1 {
    font-size: 6.5rem;
  }
  .developer {
    font-size: 6.5rem;
  }
}
@media screen and (min-width: 2550px) {
  .name-container {
    top: 10%;
    left: 40%;
    font-size: 5rem;
  }
  .thumb,
  .splat,
  .missing-thumb {
    left: -1%;
    width: 55rem;
  }
  .big-thumb {
    margin: -10% 0 0 0;
    animation: thumbs-up-massive 2.7s ease-in-out both;
  }
  .web1 {
    font-size: 7rem;
  }
  .developer {
    font-size: 7rem;
  }
}
</style>
